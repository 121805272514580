var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"120px"},attrs:{"src":require('@/assets/images/acacia-light-logo-svg.svg'),"alt":"Acacia Wilderness Mweya Logo"}})])]),_c('div',{staticClass:"side-link"},[_c('ul',[_c('el-icon',[_c('Monitor')],1),_c('li',{staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({ path: '/' })}}},[_c('i',{staticClass:"el-icon-monitor",staticStyle:{"padding-right":"5px"}}),_vm._v("Website ")]),_c('li',{class:{ active: _vm.$route.name == 'Home' },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/' })}}},[_c('i',{staticClass:"el-icon-menu",staticStyle:{"padding-right":"5px"}}),_vm._v(" Dashboard ")]),_c('li',{class:{ active: _vm.$route.name == 'BackOfficeGeneralCalendar' },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/general-calendar' })}}},[_c('i',{staticClass:"el-icon-date",staticStyle:{"padding-right":"5px"}}),_vm._v(" General Calendar ")]),_c('li',{class:{ active: _vm.$route.name == 'BackOfficeReservations' },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/reservations' })}}},[_c('i',{staticClass:"el-icon-s-check",staticStyle:{"padding-right":"5px"}}),_vm._v(" Reservations ")]),_c('li',{class:{ active: _vm.$route.name == 'BackOfficeBookings' },on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/bookings' })}}},[_c('i',{staticClass:"el-icon-check",staticStyle:{"padding-right":"5px"}}),_vm._v(" Bookings ")]),_c('li',{class:{
          active:
            _vm.$route.name == 'Categories' ||
            _vm.$route.name == 'AddProduct' ||
            _vm.$route.name == 'EditProduct' ||
            _vm.$route.name == 'BackOfficeRooms' ||
            _vm.$route.name == 'EventCalendar',
        },staticStyle:{"padding-right":"5px"},on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/categories' })}}},[_c('i',{staticClass:"el-icon-s-grid",staticStyle:{"padding-right":"5px"}}),_vm._v("Room Categories ")]),_c('li',{class:[
          _vm.$store.state.userRoles == 'RECEPTIONIST'
            ? 'hide_list'
            : 'show_list',
          { active: _vm.$route.name == 'Users' } ],on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/users' })}}},[_c('i',{staticClass:"el-icon-user-solid",staticStyle:{"padding-right":"5px"}}),_vm._v(" Team Members ")]),_c('li',{class:[
          _vm.$store.state.userRoles == 'RECEPTIONIST'
            ? 'hide_list'
            : 'show_list',
          { active: _vm.$route.name == 'ContactUsMessages' } ],on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/contact-us-messages' })}}},[_c('i',{staticClass:"el-icon-message",staticStyle:{"padding-right":"5px"}}),_vm._v(" Contact Us Messages ")]),_c('li',{class:{ active: _vm.$route.name == 'GeneralFeedBacks' },on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/general-feedbacks' })}}},[_c('i',{staticClass:"el-icon-chat-line-round",staticStyle:{"padding-right":"5px"}}),_vm._v(" General Feedbacks ")]),_c('li',{class:{ active: _vm.$route.name == 'GuestFeedBacks' },on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/guest-feedbacks' })}}},[_c('i',{staticClass:"el-icon-chat-line-round",staticStyle:{"padding-right":"5px"}}),_vm._v(" Guest Feedbacks ")]),_c('li',{class:[
          _vm.$store.state.userRoles == 'RECEPTIONIST'
            ? 'hide_list'
            : 'show_list',
          { active: _vm.$route.name == 'BackOfficeBlog' } ],on:{"click":function($event){return _vm.$router.push({ path: '/smartlodge/admin/blog' })}}},[_c('i',{staticClass:"el-icon-copy-document",staticStyle:{"padding-right":"5px"}}),_vm._v(" Blog ")]),_c('li',{on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-arrow-left",staticStyle:{"padding-right":"5px"}}),_vm._v(" Logout ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }