<template>
  <div>
    <div style="cursor: pointer">
      <div
        style="
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          :src="require('@/assets/images/acacia-light-logo-svg.svg')"
          style="width: 120px"
          alt="Acacia Wilderness Mweya Logo"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="side-link">
      <ul>

        <el-icon><Monitor /></el-icon>
        <li style="padding-right: 5px" @click="$router.push({ path: '/' })">
          <i style="padding-right: 5px" class="el-icon-monitor"></i>Website
        </li>
        <li
          style="padding-right: 5px"
          @click="$router.push({ path: '/smartlodge/admin/' })"
          :class="{ active: $route.name == 'Home' }"
        >
          <i style="padding-right: 5px" class="el-icon-menu"></i> Dashboard
        </li>

        <li
          style="padding-right: 5px"
          @click="$router.push({ path: '/smartlodge/admin/general-calendar' })"
          :class="{ active: $route.name == 'BackOfficeGeneralCalendar' }"
        >
          <i style="padding-right: 5px" class="el-icon-date"></i> General
          Calendar
        </li>

        <li
          style="padding-right: 5px"
          @click="$router.push({ path: '/smartlodge/admin/reservations' })"
          :class="{ active: $route.name == 'BackOfficeReservations' }"
        >
          <i style="padding-right: 5px" class="el-icon-s-check"></i>
          Reservations
        </li>

        <li
          @click="$router.push({ path: '/smartlodge/admin/bookings' })"
          :class="{ active: $route.name == 'BackOfficeBookings' }"
        >
          <i style="padding-right: 5px" class="el-icon-check"></i> Bookings
        </li>

        <li
          style="padding-right: 5px"
          @click="$router.push({ path: '/smartlodge/admin/categories' })"
          :class="{
            active:
              $route.name == 'Categories' ||
              $route.name == 'AddProduct' ||
              $route.name == 'EditProduct' ||
              $route.name == 'BackOfficeRooms' ||
              $route.name == 'EventCalendar',
          }"
        >
          <i style="padding-right: 5px" class="el-icon-s-grid"></i>Room
          Categories
        </li>

        <!-- <li
              style="padding-right:5px"
              @click="$router.push({path: '/smartlodge/admin/banners'})"
              :class="[$store.state.userRoles == 'RECEPTIONIST' ? 'hide_list': 'show_list', { active: $route.name == 'Banners' }]"
            >
                <i
                  style="padding-right:5px"
                  class="el-icon-s-grid"
                ></i> Gallery
            </li> -->

        <li
          @click="$router.push({ path: '/smartlodge/admin/users' })"
          :class="[
            $store.state.userRoles == 'RECEPTIONIST'
              ? 'hide_list'
              : 'show_list',
            { active: $route.name == 'Users' },
          ]"
        >
          <i style="padding-right: 5px" class="el-icon-user-solid"></i> Team
          Members
        </li>

        <li
          @click="
            $router.push({ path: '/smartlodge/admin/contact-us-messages' })
          "
          :class="[
            $store.state.userRoles == 'RECEPTIONIST'
              ? 'hide_list'
              : 'show_list',
            { active: $route.name == 'ContactUsMessages' },
          ]"
        >
          <i style="padding-right: 5px" class="el-icon-message"></i> Contact
          Us Messages
        </li>

        <li
          @click="$router.push({ path: '/smartlodge/admin/general-feedbacks' })"
          :class="{ active: $route.name == 'GeneralFeedBacks' }"
        >
          <i style="padding-right: 5px" class="el-icon-chat-line-round"></i>
          General Feedbacks
        </li>

        <li
          @click="$router.push({ path: '/smartlodge/admin/guest-feedbacks' })"
          :class="{ active: $route.name == 'GuestFeedBacks' }"
        >
          <i style="padding-right: 5px" class="el-icon-chat-line-round"></i>
          Guest Feedbacks
        </li>

        <li
          @click="$router.push({ path: '/smartlodge/admin/blog' })"
          :class="[
            $store.state.userRoles == 'RECEPTIONIST'
              ? 'hide_list'
              : 'show_list',
            { active: $route.name == 'BackOfficeBlog' },
          ]"
        >
          <i style="padding-right: 5px" class="el-icon-copy-document"></i> Blog
        </li>

        <li @click="logout">
          <i style="padding-right: 5px" class="fas fa-arrow-left"></i> Logout
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>

<style scoped>
.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-bottom: 1px solid grey;
  padding-top: 30px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 300 !important;
}
.side-link .active {
  border-left: 6px solid #ddd0a9;
  color: grey !important;
  font-weight: 300;
}
.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: grey;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>
