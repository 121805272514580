import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from "jwt-decode";
import { ecommerceStore } from './ecommerceStore'
import { INITIALIZE_STORE, LOGIN_USER } from "./mutation-types"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // accessToken: JSON.parse(localStorage.getItem("user")),
    // decodedToken: jwt_decode(JSON.parse(localStorage.getItem("user"))),
    accessToken: "",
    userId: "",
    currentEmail: "user@email.com",
    firstName: "",
    fullName: "Full Name",
    profilePicture: "",
    userRoles: "Role",
    logoImageUrl: "assets/images/acacia-light-logo-svg.svg",
    counter: 0,
    currentCurrency: 'UGX',
  },

  //Synchronous
  //mutations are defined methods which have business logic to update the state.
  mutations: {
    
    [LOGIN_USER]: (state, { accessToken }) => {
      //Save the Device Token as we need it for Single User Sessions

      try {
        //@ts-ignore access Token
        const decoded = jwt_decode(accessToken);
        //@ts-ignore access Token
        state.userId = decoded.result.user_id;
        //@ts-ignore access Token
        state.profilePicture = decoded.result.profile_picture;
        //@ts-ignore access Token
        state.firstName = decoded.result.first_name;
        //@ts-ignore access Token
        state.userRoles = decoded.role;
        state.accessToken = accessToken;

        //@ts-ignore access Token
        state.fullName = `${decoded.result.first_name} ${decoded.result.last_name}`;

        //@ts-ignore Device Token
        state.currentEmail = `${decoded.result.email}`;

        //@ts-ignore access Token

      } catch (error) {
        //Nothing to do
      }

      //Save The Access Token in local Storage 
      localStorage.setItem("user", accessToken);
    },

    [INITIALIZE_STORE]: (state) => {
      //Retrieve Device Token
      //@ts-ignore access Token
      const accessToken = localStorage.getItem("user");
      //If we have no access token, No use to proceed
      if (!accessToken) return localStorage.removeItem("user");
      try {

        const decoded = jwt_decode(accessToken);
        //@ts-ignore Device Token
        state.userId = decoded.result.user_id;
        state.accessToken = accessToken;

        //@ts-ignore Device Token
        // state.userInitials = decoded.result.ui;
        //@ts-ignore Device Token
        state.profilePicture = decoded.result.profile_picture;
        //@ts-ignore Device Token
        state.firstName = decoded.result.first_name;
        //@ts-ignore Device Token
        state.userRoles = decoded.result.role;
        //@ts-ignore Device Token

        //@ts-ignore Device Token
        state.fullName = `${decoded.result.first_name} ${decoded.result.last_name}`;

        //@ts-ignore Device Token
        state.currentEmail = `${decoded.result.email}`;

      } catch (error) {
        //Nothing to do
        //TODO: HANDLE THIS SCENARIO
      }
    },

    incrementCounter(state) {
      state.counter++;
    },

    increase(state, payload) {
      state.counter = state.counter + payload.value;
    },
    
    setCurrentCurrency(state, payload) {
      state.currentCurrency = payload;
  },
  },

  //Asynchronous
  //Actions are important for asynchronous code, and in general it is a good practice to use actions.
  //Actions are allowed to run asynchronous code. (Unlike mutations)
  //An action is just a method
  //You can use the same name here as you do in the mutations makes sense.
  //The actions gets an object as an argument. A context object.
  //The *context* object has a commit field, which commits a mutation.
  //You can pass a second argument in the commit as a payload. 
  /**
  *We have the context object which we get as argument automatically, 
  *and then it is full of interesting things. 
  *We can also dispatch another action, from inside another action.
  *We can also access getters, incase we need some calculated value.
  *We can also access the state. Though we should not manipulate the state from inside an action.
  */

  actions: {
    incrementCounter(context) {
      context.commit("incrementCounter");
    },

    increase(context, payload) {
      context.commit("increase", payload);
    },

    initializeStore(context) {
      context.commit("initializeStore");
    }

  },

  //Getters are like computed properties in the store.
  //We can have multiple getters, and the getters can depend on each other.s
  getters: {
    //Getters take two parameters (The state, and the getters)
    another(state) {
      return state.counter * 100;
    },

    checkingAnother(state, getters) {
      if (getters.another < 10000) {
        return "We are less";
      } else if (getters.another == 10000) {
        return "We are Medium";
      } else {
        return "We are Great";
      }
    },

    isQualityAssurance: state => {
      //@ts-ignorev  opuuu
      return state.userRoles.includes('QA');
    },
    
    getCurrentCurrency: state => state.currentCurrency,

  },


  plugins: [ecommerceStore],

  // plugins: [ecommerceStore],
  modules: {
  }
})
